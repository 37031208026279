import createApp from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from 'buefy';
import axios from 'axios';
import 'font-awesome/css/font-awesome.css';
import 'font-awesome/css/font-awesome.min.css';
import '../style.css';

createApp.use(Buefy, {
  defaultIconPack: 'fa'
})
createApp.config.productionTip = false

new createApp({
	el: "#app",
	router,
	axios,
    render:h=> h(App)
}).$mount('#app')
